type errorsType = {
    [K: string]: {
        title: string;
        message: string;
    };
};

const errors: errorsType = {
    "404": {
        title: "Error 404! Not Found",
        message: "Page Not Found!"
    },
    "403": {
        title: "Error 403! Forbidden",
        message: "You do not have permission to access the content!"
    },
    "500": {
        title: "Error 500! Internal Server Error",
        message: "The server was unable to complete your request."
    },
    "*": {
        title: "Unknown error!",
        message: ""
    }
};

export default errors;
