
import Vue from "vue";
import Component from "vue-class-component";
import errors from "./Errors";

@Component
export default class Error extends Vue {
    get statusCode(): number | null {
        const code = Number(this.$route.params.code);
        return !isNaN(code) ? code : null;
    }

    get title(): string {
        if (this.statusCode && errors[this.statusCode]) {
            return errors[this.statusCode].title;
        }
        return errors["*"].title;
    }

    get message(): string {
        if (this.statusCode && errors[this.statusCode]) {
            return errors[this.statusCode].message;
        }
        return errors["*"].message;
    }
}
